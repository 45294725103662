<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Buyurtma o'zgartirish</h3>
          </div>
        </div>

        <div class="card-body">
          <form>
            <div v-if="getZayavkaDetails.deliver == 'POS'">
              <h2>Postavshik</h2>
              <div class="d-flex flex-wrap">
                <div class="col-3">
                  <v-text-field
                    :disabled="getZayavkaDetails.zayavka_owner"
                    outlined
                    label="Mijoz nomi"
                    v-model="getZayavkaDetails.zayavka_owner"
                    dense
                  ></v-text-field>
                </div>

                <div class="col-3" v-if="getZayavkaDetails.deliver == 'POS'">
                  <v-text-field
                    :disabled="getZayavkaDetails.deliver"
                    outlined
                    label="Yetkazib beruvchi"
                    value="Postavshik"
                    dense
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    outlined
                    v-currency="options"
                    label="Summa"
                    dense
                    v-model="getZayavkaDetails.price"
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-currency="options"
                    label="NDS summa"
                    v-model="getZayavkaDetails.nds_summa"
                    dense
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    outlined
                    label="Summa(NDS)"
                    v-currency="options"
                    v-model="getZayavkaDetails.price_with_nds"
                    dense
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-mask="'##-##-####'"
                    label="Yuk ketish sanasi"
                    dense
                    v-model="getZayavkaDetails.start_date"
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-dialog
                    ref="dialog2"
                    v-model="modal"
                    :return-value.sync="getZayavkaDetails.start_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="getZayavkaDetails.start_time"
                        label="Boshlanish vaqti"
                        append-icon="access_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-time-picker
                      v-if="modal"
                      v-model="getZayavkaDetails.start_time"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="modal = false"
                        >Bekor qilish</v-btn
                      >
                      <v-btn
                        small
                        color="primary"
                        @click="
                          $refs.dialog2.save(getZayavkaDetails.start_time)
                        "
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-mask="'##-##-####'"
                    label="Yetib borish sanasi"
                    dense
                    v-model="getZayavkaDetails.end_date"
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-dialog
                    ref="dialog"
                    v-model="modal3"
                    :return-value.sync="getZayavkaDetails.end_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="getZayavkaDetails.end_time"
                        label="Yetib borish vaqti"
                        append-icon="access_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-time-picker
                      v-if="modal3"
                      v-model="getZayavkaDetails.end_time"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="modal3 = false"
                        >Bekor qilish</v-btn
                      >
                      <v-btn
                        small
                        color="primary"
                        @click="$refs.dialog.save(getZayavkaDetails.end_time)"
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </div>

                <div class="col-3">
                  <v-text-field
                    label="Kg"
                    dense
                    v-model="getZayavkaDetails.post_kg"
                    outlined
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    label="m3"
                    dense
                    v-model="getZayavkaDetails.post_m3"
                    outlined
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-currency="options"
                    label="Narxi"
                    dense
                    v-model="getZayavkaDetails.postavshik_price"
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-currency="options"
                    label="NDS summa"
                    v-model="getZayavkaDetails.postavshik_nds_summa"
                    dense
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    outlined
                    label="NSD bilan Narxi"
                    v-currency="options"
                    v-model="getZayavkaDetails.postavshik_price_with_nds"
                    dense
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    label="Postavchik haydovchi"
                    dense
                    v-model="getZayavkaDetails.postavshik_driver"
                    outlined
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    label="Posavshik mashina ma'lumoti"
                    dense
                    v-model="getZayavkaDetails.postavshik_truck_info"
                    outlined
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    label="Posavshik mashina nomeri"
                    dense
                    v-model="getZayavkaDetails.postavshik_truck_number"
                    outlined
                  ></v-text-field>
                </div>
              </div>
              <div class="col">
                <v-text-field
                  outlined
                  dense
                  label="Izoh"
                  v-model="getZayavkaDetails.comment"
                ></v-text-field>
              </div>
              <!-- <div class="col-3">
                <v-text-field
                  label="m3"
                  dense
                  v-model="getZayavkaDetails.post_m3"
                  outlined
                ></v-text-field>
              </div>
              <div class="col">
                <v-textarea
                  outlined
                  dense
                  label="Izoh"
                  v-model="getZayavkaDetails.comment"
                ></v-textarea>
              </div>

              <div class="col-3">
                <v-text-field
                  label="Postavchik haydovchi"
                  dense
                  v-model="getZayavkaDetails.postavshik_driver"
                  outlined
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  label="Posavshik mashina ma'lumoti"
                  dense
                  v-model="getZayavkaDetails.postavshik_truck_info"
                  outlined
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  label="Posavshik mashina nomeri"
                  dense
                  v-model="getZayavkaDetails.postavshik_truck_number"
                  outlined
                ></v-text-field>
              </div> -->
            </div>

            <div v-if="getZayavkaDetails.deliver == 'BTS'">
              <h2>BTS</h2>
              <div class="d-flex flex-wrap">
                <div class="col-3">
                  <v-text-field
                    :disabled="getZayavkaDetails.zayavka_owner"
                    outlined
                    label="Mijoz nomi"
                    v-model="getZayavkaDetails.zayavka_owner"
                    dense
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    :disabled="getZayavkaDetails.deliver"
                    outlined
                    label="Yetkazib beruvchi"
                    v-model="getZayavkaDetails.deliver"
                    dense
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    outlined
                    label="Asosiy haydovchi"
                    v-model="getZayavkaDetails.main_driver"
                    dense
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    label="Yordamchi haydovchi"
                    v-model="getZayavkaDetails.assistant_driver"
                    dense
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-currency="options"
                    label="Summa"
                    dense
                    v-model="getZayavkaDetails.price"
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-currency="options"
                    label="NDS summa"
                    v-model="getZayavkaDetails.nds_summa"
                    dense
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-text-field
                    outlined
                    label="Summa(NDS)"
                    v-currency="options"
                    v-model="getZayavkaDetails.price_with_nds"
                    dense
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-mask="'##-##-####'"
                    label="Yuk ketish sanasi"
                    dense
                    v-model="getZayavkaDetails.start_date"
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-dialog
                    ref="dialog2"
                    v-model="modal"
                    :return-value.sync="getZayavkaDetails.start_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="getZayavkaDetails.start_time"
                        label="Boshlanish vaqti"
                        append-icon="access_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-time-picker
                      v-if="modal"
                      v-model="getZayavkaDetails.start_time"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="modal = false"
                        >Bekor qilish</v-btn
                      >
                      <v-btn
                        small
                        color="primary"
                        @click="
                          $refs.dialog2.save(getZayavkaDetails.start_time)
                        "
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    v-mask="'##-##-####'"
                    label="Yetib borish sanasi"
                    dense
                    v-model="getZayavkaDetails.end_date"
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-dialog
                    ref="dialog"
                    v-model="modal3"
                    :return-value.sync="getZayavkaDetails.end_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="getZayavkaDetails.end_time"
                        label="Yetib borish vaqti"
                        append-icon="access_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-time-picker
                      v-if="modal3"
                      v-model="getZayavkaDetails.end_time"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="modal3 = false"
                        >Bekor qilish</v-btn
                      >
                      <v-btn
                        small
                        color="primary"
                        @click="$refs.dialog.save(getZayavkaDetails.end_time)"
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </div>

                <div class="col-3">
                  <v-text-field
                    label="Kg"
                    dense
                    v-model="getZayavkaDetails.post_kg"
                    outlined
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    label="m3"
                    dense
                    v-model="getZayavkaDetails.post_m3"
                    outlined
                  ></v-text-field>
                </div>
              </div>
              <div class="col">
                <v-text-field
                  outlined
                  dense
                  label="Izoh"
                  v-model="getZayavkaDetails.comment"
                ></v-text-field>
              </div>
            </div>

            <v-btn color="success" class="w-100" @click="submit"
              >Yuborish</v-btn
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(
        new Date().toString().split('GMT')[0] + ' UTC'
      ).toISOString(),
      modal: false,
      modal3: false,
      value: null,
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false,
      placement_expenditure: null,
      id: ''
    }
  },
  created() {
    this.$store.dispatch('getZayavkaDetails', this.$route.params.id)
    this.$store.dispatch('getAllProvidersWithoutPg')
  },
  watch: {
    getZayavkaDetails: function (val) {
      if (val.postavshik !== null && val.postavshik !== undefined) {
        this.$store.dispatch('getProvidersContractById', val.postavshik)
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllDrivers')
  },
  computed: {
    providerContracts() {
      const data = this.$store.state.requests.providerContract
      return data
    },
    providers() {
      return this.$store.state.requests.allProvidersWithoutPg
    },
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    getZayavkaDetails() {
      const data = this.$store.state.requests.zayavkaById
      return data
    }
  },
  methods: {
    getContract(val) {
      if (val !== null && val !== undefined) {
        this.$store.dispatch('getProvidersContractById', val)
      }
    },
    toFloat(val) {
      if (typeof val == 'string') {
        return parseInt(val.replace(/[ ,]/g, ''), 10)
      } else if (typeof val == 'number') return val
      else return 0
    },
    submit() {
      const data = {
        nds_summa: this.toFloat(this.getZayavkaDetails.nds_summa),
        price_with_nds: this.toFloat(this.getZayavkaDetails.price_with_nds),
        price: this.toFloat(this.getZayavkaDetails.price),
        postavshik_price: this.toFloat(this.getZayavkaDetails.postavshik_price),
        postavshik_nds_summa: this.toFloat(
          this.getZayavkaDetails.postavshik_nds_summa
        ),
        postavshik_price_with_nds: this.toFloat(
          this.getZayavkaDetails.postavshik_price_with_nds
        ),
        start_date:
          this.getZayavkaDetails.start_date.split('-').reverse().join('-') +
          this.date.substring(10),
        end_date:
          this.getZayavkaDetails.end_date
            .split('-')

            .reverse()
            .join('-') + this.date.substring(10),
        comment: this.getZayavkaDetails.comment,
        post_kg: this.getZayavkaDetails.post_kg,
        post_m3: this.getZayavkaDetails.post_m3,
        postavshik: this.getZayavkaDetails.postavshik,
        postavshik_contract: this.getZayavkaDetails.postavshik_contract,
        postavshik_driver: this.getZayavkaDetails.postavshik_driver,
        main_driver: this.getZayavkaDetails.main_driver,
        postavshik_truck_info: this.getZayavkaDetails.postavshik_truck_info,
        postavshik_truck_number: this.getZayavkaDetails.postavshik_truck_number,
        assistant_driver: this.getZayavkaDetails.assistant_driver
      }
      console.log(data)
      this.$store.dispatch('updateZayavkaDetails', {
        id: this.$route.params.id,
        data: data
      })
    }
  }
}
</script>

<style></style>
